<template>
    <div class="w-100">
        <vue-headful :title="$t('title_skill_reading_level')" description="title" />
        <v-container>
            <v-row cols="12" style="margin-top:20px">
                <BeeTabs @changeTab="changeTab" :items="items" tab-color="secondary" />
            </v-row>
            <v-row cols="12">
                <router-view />
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default {
    name: 'SkillReadingLevel',
    data() {
        return {
            currentTab: 0,
        }
    },
    methods: {
        changeTab(tab) {
            if (tab === 0) {
                this.$router.push('/teacher/report-skill-level/beereaders-level-system')
            }
        },
    },
    computed: {
        items() {
            return [this.$t('reporting.level_system')]
        },
    },
    beforeMount() {
        this.changeTab(this.currentTab)
    }
}
</script>
